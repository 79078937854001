import { toaster } from 'baseui/toast';
import { isUndefined } from 'lodash';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { Dot } from '../../Components/Flair';
import { AsyncListView } from '../../Components/ListView/AsyncListView';
import { PermsOnly } from '../../Components/Permissions';
import { PaddedPage } from '../../globalStyles';
import {
  Permission,
  usePaginatedUserIdentityVerificationQuery,
  UserIdentityVerificationInputSortBy,
  UserIdentityVerificationInputSortDirection,
  UserIdentityVerificationStatus,
} from '../../graphQL';
import { getFullName } from '../../modelUtils/users';
import { PageTitle } from '../Shared';
import { VerifiableUser, verificationColorMap } from './utils';
import { VerificationModal } from './VerificationModal';

export const VerificationPage = () => {
  const [currentUser, setCurrentUser] = useState<VerifiableUser | undefined>(undefined);
  const { refetch } = usePaginatedUserIdentityVerificationQuery({ variables: { input: {} } });

  const fetchData = useCallback(
    async (uiState, prevPage) => {
      console.log('uiState: ', uiState.sortColumn, uiState.sortDirection);
      const res = await refetch({
        input: {
          cursor: prevPage?.cursor,
          limit: uiState.limit,
          status: uiState.filterValues?.status,
          sortBy: uiState.sortColumn,
          sortDirection:
            uiState.sortDirection === 'desc'
              ? UserIdentityVerificationInputSortDirection.Descending
              : UserIdentityVerificationInputSortDirection.Ascending,
        },
      });
      if (!res.data || !res.data.paginatedUserIdentityVerification)
        throw new Error('Error fetching');

      return {
        items: res.data.paginatedUserIdentityVerification.items,
        cursor: res.data.paginatedUserIdentityVerification.cursor,
        hasMore: res.data.paginatedUserIdentityVerification.hasMore,
        count: res.data.paginatedUserIdentityVerification.count,
      };
    },
    [refetch]
  );

  const handleClose = (accepted?: boolean) => {
    setCurrentUser(undefined);
    if (!isUndefined(accepted)) {
      refetch();
      accepted ? toaster.positive('ID Accepted', {}) : toaster.negative('ID Rejected', {});
    }
  };

  return (
    <PermsOnly allowed={Permission.MantraAdmin}>
      <PaddedPage>
        <PageTitle>ID Verification</PageTitle>
        {currentUser && (
          <VerificationModal
            verifiableUser={currentUser}
            onClose={accepted => handleClose(accepted)}
          />
        )}
        <AsyncListView
          fetchData={fetchData}
          persist
          getKey={({ user }) => user.id}
          columns={[
            {
              key: UserIdentityVerificationInputSortBy.Name,
              title: 'Patient',
              sortable: true,
              render: ({ user }) => getFullName(user),
            },
            {
              key: UserIdentityVerificationInputSortBy.IdentificationCardCreatedAt,
              title: 'Submitted ID',
              sortable: true,
              render: ({ user }) =>
                user.identificationCard
                  ? moment(user.identificationCard.createdAt).fromNow()
                  : 'Never',
            },
            {
              key: 'status',
              title: 'Status',
              sortable: false,
              render: ({ status }) => {
                return <Dot color={verificationColorMap[status]}>{status}</Dot>;
              },
            },
          ]}
          filters={[
            {
              key: 'status',
              placeholder: 'All Users',
              options: [
                { id: UserIdentityVerificationStatus.Unverified, label: 'Unverified Users' },
                { id: UserIdentityVerificationStatus.Verified, label: 'Verified Users' },
                { id: UserIdentityVerificationStatus.Rejected, label: 'Rejected Users' },
                { id: UserIdentityVerificationStatus.Incomplete, label: 'Incomplete Users' },
              ],
              width: '18rem',
              iconLeft: 'iconsClipboardSvg',
            },
          ]}
          onClick={user => setCurrentUser(user as VerifiableUser)}
        />
      </PaddedPage>
    </PermsOnly>
  );
};
